<template lang="pug">
    .wrap
        .item.top
            ui-title.title(variant="subtitle-2", component="div")
                em SARS‑CoV‑2
            .icon-wrap
                article-icon.icon(src="hex_green" class="opacity-50", :style="style.top")
                img(
                    src="../../../../assets/images/articles/1/composed/bacteria/1.svg",
                    alt="",
                    loading="lazy",
                    :style="{ '--shiftX': -1, '--shiftY': 1, ...style.image }"
                )
        .item.bot
            .icon-wrap
                article-icon.icon(src="hex_green" class="opacity-50", :style="style.bottom")
                img(
                    src="../../../../assets/images/articles/1/composed/bacteria/2.svg",
                    alt="",
                    loading="lazy",
                    :style="{ '--shiftX': 1, '--shiftY': -1, ...style.image }"
                )
            ui-title.title(variant="subtitle-2", component="div")
                em Mycobacterium tuberculosis
</template>

<script>
import ArticleIcon from '../../Icon.vue';
import UiTitle from '../../../ui/Title.vue';

export default {
    name: 'bacteria',
    components: {
        ArticleIcon,
        UiTitle,
    },
    props: {
        mouse: {
            type: Object,
            required: true,
        },
    },
    computed: {
        style() {
            const { x, y } = this.mouse;
            const translateX = -1 * x * 8;
            const translateY = y * 8;
            const scaleCoeff = 1 - (x * 0.08);

            return {
                top: {
                    transform: `scale(${scaleCoeff})`,
                },
                bottom: {
                    transform: `scale(${scaleCoeff})`,
                },
                image: {
                    transform: `translate(calc(${translateX}px * var(--shiftX)), calc(${translateY}px * var(--shiftY)))`,
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: vw(264);
    max-width: 340px;
    margin: 0 auto;
    position: relative;

    @media (--viewport-tablet) {
        width: rem-mobile(280);
        max-width: var(--illn-max-width);
    }

    .item {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .title {
            max-width: rem(130);

            @media (--viewport-tablet) {
                max-width: rem-mobile(130);
            }
        }

        .icon-wrap {
            width: rem(126);
            height: rem(107);
            margin-left: rem(12);
            margin-right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            @media (--viewport-tablet) {
                width: rem-mobile(126);
                height: rem-mobile(107);
                margin-left: rem-mobile(12);
            }

            img {
                max-width: 60%;
                max-height: 75%;
                object-fit: contain;
            }

            .icon {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }

        &.bot {
            justify-content: flex-start;

            .icon-wrap {
                margin-left: 0;
                margin-right: rem(12);

                @media (--viewport-tablet) {
                    margin-right: rem-mobile(12);
                }
            }
        }
    }
}
</style>
