<template lang="pug">
    .wrap
        illustration.bacteria
</template>

<script>
const Illustration = () => import(
    /* webpackChunkName: 'bacteria-2-illn' */ '../../../../assets/images/articles/2/composed/bacteria.svg?inline'
);

export default {
    name: 'bacteria-2',
    components: {
        Illustration,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: rem(200);
    max-width: 240px;
    margin: 0 auto;
    position: relative;

    .bacteria {
        width: 100%;
        object-fit: contain;
        overflow: visible;

        &_svg {
            &__bacteria-2-polygon {
                transform-origin: 50% 50%;
                animation: poly-scale 2.5s ease-out-cubic infinite;

                @keyframes poly-scale {
                    0% {
                        transform: scale(0.6);
                        opacity: 0;
                    }

                    35% {
                        opacity: 1;
                    }

                    75% {
                        opacity: 1;
                    }

                    100% {
                        transform: scale(1);
                        opacity: 0;
                    }
                }
            }
        }
    }
}
</style>
