<template lang="pug">
    intersect(
        @change="([entry]) => handleIntersection(entry)",
        :threshold="[0, 0.5]"
    )
        article.product-block
            ui-title.subtitle(v-html="subtitle", variant="subtitle-3")
            ui-title(v-html="title")
            ul.pros-list
                li.list-item(
                    v-for="(item, index) in pros",
                    :key="index"
                )
                    article-icon.icon(src="hex")
                    ui-paragraph(v-html="item")
            ui-title.lead(v-html="lead", variant="subtitle-2", component="p")
            ui-paragraph.lead.lead-bottom(v-html="leadBottom")
            .links
                a.link-wrap(
                    :href="link.href",
                    target="_blank"
                    rel="noopener noreferrer",
                    aria-label="Узнать больше о продукте",
                    @click="$analytics.linkClick(link.href)"
                )
                    article-icon.link-icon(src="hex")
                    ui-title(v-html="link.text", variant="subtitle-2", component="span")
                a.link-wrap.green(
                    :href="observationLink.href",
                    target="_blank"
                    rel="noopener noreferrer",
                    aria-label="Узнать больше о продукте",
                    @click="$analytics.linkClick(observationLink.href)"
                )
                    article-icon.link-icon(src="product_link")
                    ui-title(v-html="observationLink.text", variant="subtitle-2", component="span")
</template>

<script>
import { mapActions } from 'vuex';
import Intersect from 'vue-intersect';

import ArticleIcon from './article/Icon.vue';
import UiParagraph from './ui/Paragraph.vue';
import UiTitle from './ui/Title.vue';

export default {
    name: 'app-product',
    components: {
        ArticleIcon,
        Intersect,
        UiParagraph,
        UiTitle,
    },
    computed: {
        title: () => 'Преимущества теста<br/> <em>Т-СПОТ.ТБ:</em>',
        // eslint-disable-next-line max-len
        subtitle: () => '<nobr><em>Т-СПОТ.ТБ</em></nobr>&nbsp;&mdash; это анализ крови для выявления латентной и&nbsp;активной туберкулезной инфекции, который относится к&nbsp;группе <em>IGRA</em>&nbsp;&mdash; тестов на&nbsp;анализ секреции гамма-интерферона (Interferon gamma release assay).',
        // eslint-disable-next-line max-len
        lead: () => 'Тест <nobr><em>Т-СПОТ.ТБ</em></nobr> разработан исследователями из&nbsp;Оксфорда и&nbsp;одобрен к&nbsp;применению в&nbsp;большинстве стран мира, США и&nbsp;России. С&nbsp;2019 года <nobr>Т-СПОТ.ТБ</nobr> производится в&nbsp;России<sup>*</sup>.',
        leadBottom: () => '<sup>*</sup> <nobr>Т-СПОТ.ТБ</nobr> производится научно-производственной компанией &laquo;Генериум&raquo;',
        link: () => ({
            text: 'УЗНАТЬ БОЛЬШЕ',
            href: 'http://www.generium.ru/?utm_source=lenta.ru&utm_medium=special&utm_content=landingpage',
        }),
        observationLink: () => ({
            text: 'Пройти обследование',
            href: 'https://www.labquest.ru/?utm_source=lenta.ru&utm_campaign=tspot-generium',
        }),
        pros: () => ([
            'высокая специфичность (99%) и&nbsp;чувствительность (98,8%)',
            'высокая точность диагностики во&nbsp;всех возрастных группах',
            'не&nbsp;дает ложных результатов у&nbsp;БЦЖ-вакцинированных',
            'отсутствие влияния приема лекарственных препаратов на&nbsp;результаты тестирования',
            'позволяет диагностировать внелегочные формы туберкулеза',
            'не&nbsp;имеет противопоказаний и&nbsp;ограничений по&nbsp;времени проведения',
            // eslint-disable-next-line max-len
            'позволяет диагностировать инфекцию на&nbsp;фоне иммунодефицитных состояний, поэтому предпочтителен при обследовании лиц с&nbsp;ВИЧ‑инфекцией',
            'проводится за&nbsp;одно посещение специалиста',
        ]),
    },
    methods: {
        ...mapActions('intersection', ['patchEntries']),
        handleIntersection(entry) {
            this.patchEntries({ productBlock: entry });
        },
    },
};
</script>

<style lang="scss" scoped>
.product-block {
    width: 100%;
    margin: rem(60) 0 0 0;
    padding: rem(52) var(--offset-x) rem(92);
    background-color: var(--light-green);

    .subtitle {
        margin-bottom: rem(80);
    }

    .pros-list {
        width: vw(570);
        min-width: 570px;
        max-width: 750px;
        margin: rem(64) auto rem(52);
        padding: rem(34) 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
        position: relative;
        list-style-type: none;

        .list-item {
            width: 47.5%;
            margin-bottom: rem(24);
            position: relative;

            .icon {
                width: rem(18);
                height: rem(16);
                position: absolute;
                top: 0;
                right: 100%;
                transform: translate(-50%, 0%);
            }

            &:nth-last-child(1),
            &:nth-last-child(2) {
                margin-bottom: 0;
            }
        }

        &::before,
        &::after {
            content: '';
            width: 100%;
            height: rem(320);
            position: absolute;
            top: 0;
            z-index: 0;
            background: url('../assets/images/icons/decoration/product-block.svg');
            background-size: auto 100%;
            background-position: right top;
        }

        &::before {
            right: 110%;
        }

        &::after {
            left: 110%;
            transform: scaleX(-1);
        }
    }

    .lead {
        width: vw(570);
        min-width: 570px;
        max-width: 750px;
        margin: rem(52) auto;
        margin-bottom: 0;
    }

    .lead-bottom {
        margin: rem(20) auto rem(52);
        font-size: rem(14);
        color: var(--color-main-text);

        &::v-deep sup {
            font-size: 100%;
            line-height: 1;
        }
    }

    .links {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .link-wrap {
        width: rem(216);
        height: rem(122);
        margin: 0 rem(20);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--light-green);

        .link-icon {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            ::v-deep img {
                width: 100%;
                height: 100%;
                object-fit: cover !important;
            }
        }

        span {
            color: currentColor;
        }

        &.green {
            width: rem(348);
            color: var(--teal);

            .link-icon {
                ::v-deep img {
                    object-fit: contain !important;
                }
            }
        }
    }

    @media (--viewport-tablet) {
        margin-top: rem-mobile(120);
        padding: rem-mobile(24) var(--offset-x);

        .subtitle {
            margin-bottom: rem-mobile(72);
        }

        .pros-list {
            width: 100%;
            min-width: unset;
            max-width: unset;
            margin: rem-mobile(32) 0;
            padding: 0;
            display: block;
            position: relative;
            list-style-type: none;

            .list-item {
                width: 100%;
                margin-bottom: rem-mobile(24);
                padding-left: rem-mobile(18 + 8);

                .icon {
                    width: rem-mobile(18);
                    height: rem-mobile(16);
                    left: 0;
                    right: unset;
                    transform: none;
                }

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: rem-mobile(24);
                }
            }

            &::before,
            &::after {
                content: none;
                display: none;
            }
        }

        .lead {
            width: 100%;
            min-width: unset;
            max-width: unset;
            margin: rem-mobile(32) 0;
        }

        .lead-bottom {
            margin: rem-mobile(8) 0 rem-mobile(32);
        }

        .links {
            display: block;
        }

        .link-wrap {
            width: rem-mobile(280);
            height: rem-mobile(97);
            margin: rem(36) auto;

            &.green {
                width: rem-mobile(280);
                height: rem-mobile(97);

                .link-icon {
                    ::v-deep img {
                        object-fit: cover !important;
                    }
                }
            }
        }
    }
}
</style>
