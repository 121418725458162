<template lang="pug">
    aside.divider(aria-hidden="true")
        img(:src="require(`../../assets/images/service/dividers/${image}.${imageType}`)", alt="", loading="lazy" @error="")
</template>

<script>
import { requiredString, articleId } from '../../assets/js/prop-types';

export default {
    name: 'article-divider',
    props: {
        image: {
            validator: requiredString,
            required: true,
        },
        imageType: {
            validator: requiredString,
            default: 'svg',
        },
        currentArticleId: articleId,
    },
};
</script>

<style lang="scss" scoped>
.divider {
    width: 100%;
    margin: rem(45) 0;
    position: relative;

    &::before,
    &::after {
        content: url('../../assets/images/icons/decoration/section-start-right.svg');
        position: absolute;
        top: 0;
        z-index: -1;
    }

    &::before {
        left: 0;
        transform-origin: 50% 50%;
        transform: scaleX(-1);
    }

    &::after {
        right: 0;
    }

    img {
        margin: 0 auto;
        display: block;
    }

    @media (--viewport-tablet) {
        margin: rem-mobile(52) 0;

        img {
            max-width: 70%;
        }
    }
}
</style>
