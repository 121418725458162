function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('intersect',{on:{"~enter":function($event){_vm.show = true}}},[_c('article',{staticClass:"full-width",class:[_vm.order, _vm.show && 'show'],style:(_vm.$platform.isMobile ? {} : { height: _vm.height })},[_c('div',{ref:"text",staticClass:"text-wrap"},_vm._l((_vm.parsedContent),function(ref,index){
var type = ref.type;
var text = ref.text;
var is = ref.is;
var rest = objectWithoutProperties( ref, ["type", "text", "is"] );
var props = rest;
return _c(is,_vm._b({key:index,tag:"component"},'component',props,false),[(type !== 'icon')?_c('span',{domProps:{"innerHTML":_vm._s(text)}}):_vm._e()])}),1),_c('div',{staticClass:"image-wrap",attrs:{"aria-hidden":"true"}},[(_vm.imageExt === 'png')?_c('div',{staticClass:"img-cont"},[_c('img',{style:({ 'object-fit': 'contain' }),attrs:{"src":require(("../../assets/images/articles/" + _vm.currentArticleId + "/" + _vm.image + ".png")),"alt":"Изображение к блоку текста","loading":"lazy"}})]):_c('div',{staticClass:"img-cont"},[_c('picture',[_c('source',{attrs:{"srcset":require(("../../assets/images/articles/" + _vm.currentArticleId + "/" + _vm.image + ".webp")),"type":"image/webp"}}),_c('source',{attrs:{"srcset":require(("../../assets/images/articles/" + _vm.currentArticleId + "/" + _vm.image + ".jpg")),"type":"image/jpeg"}}),_c('img',{attrs:{"src":require(("../../assets/images/articles/" + _vm.currentArticleId + "/" + _vm.image + ".jpg")),"alt":"Изображение к блоку текста","loading":"lazy"}})])]),_c('article-icon',{staticClass:"icon pulse opacity-75 multiply",style:({ top: 0, '--trf': -1 }),attrs:{"src":"hex_green"}}),_c('article-icon',{staticClass:"icon pulse opacity-75 multiply",style:({ bottom: 0, '--trf': 1 }),attrs:{"src":"hex_green"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }