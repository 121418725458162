<template lang="pug">
    .wrap
        illustration.dna
</template>

<script>
const Illustration = () => import(/* webpackChunkName: 'article-dna' */ '../../../../assets/images/articles/1/composed/dna.svg?inline');

export default {
    name: 'dna',
    components: {
        Illustration,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: vw(253);
    max-width: 320px;
    margin: 0 auto;

    @media (--viewport-tablet) {
        width: 100%;
        max-width: var(--illn-max-width);
    }
}

.dna {
    width: 100%;
    object-fit: contain;
    overflow: visible;

    .dna_svg {
        &__rect {
            will-change: transform;
            animation: dna-rect 2.75s ease infinite;

            @keyframes dna-rect {
                0% {
                    transform: translateX(0);
                }

                50% {
                    transform: translateX(calc(var(--shift) * 30%));
                }

                100% {
                    transform: translateX(0);
                }
            }
        }

        &__hex {
            will-change: transform;
            animation: dna-hex 5s ease-in-out-cubic infinite;

            @keyframes dna-hex {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform:
                        translate(
                            calc(var(--shift) * 10px),
                            calc(var(--shift) * 10px),
                        );
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }
    }
}
</style>
