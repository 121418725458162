<template lang="pug">
    ui-doctor-image(
        :mouse="mouse"
        :src="require('../../../../assets/images/articles/2/composed/doctor_2/mister.svg')",
        :leftDecorationSrc="require('../../../../assets/images/articles/2/composed/doctor_2/icon.svg')"
    )
</template>

<script>
import UiDoctorImage from '../../../ui/DoctorImage.vue';

export default {
    name: 'doctor-2',
    components: {
        UiDoctorImage,
    },
    props: {
        mouse: {
            type: Object,
            required: true,
        },
    },
};
</script>
