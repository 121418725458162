<template lang="pug">
    intersect(@enter.once="show = true")
        ui-title.article-title(v-html="text", :class="{ 'show': show, [decoration]: decoration }")
</template>

<script>
import Intersect from 'vue-intersect';

import UiTitle from '../ui/Title.vue';

import { requiredString, articleId } from '../../assets/js/prop-types';

export default {
    name: 'article-title',
    components: {
        Intersect,
        UiTitle,
    },
    props: {
        text: {
            validator: requiredString,
            required: true,
        },
        currentArticleId: articleId,
        decoration: {
            type: String,
            default: '',
        },
    },
    data: () => ({ show: false }),
};
</script>

<style lang="scss" scoped>
.article-title {
    padding: 0 var(--offset-x);
    position: relative;
    opacity: 0;
    transform: translateY(40%) skew(3.5deg);
    will-change: transform;

    &.show {
        opacity: 1;
        transform: translateY(0) skew(0);
        transition: transform 0.5s ease-out-cubic, opacity 0.3s ease-out-cubic;
        will-change: initial;
    }

    &::before {
        content: url('../../assets/images/icons/decoration/section-start-left.svg');
        left: 0;
        transform: translateY(-50%);
    }

    &::after {
        content: url('../../assets/images/icons/decoration/section-start-right.svg');
        right: 0;
    }

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        z-index: -1;
        pointer-events: none;
    }

    &.wide {
        &::before {
            content: url('../../assets/images/icons/decoration/section-start-left-wide.svg');
            top: 0;
            left: 0;
            transform: translateY(-1rem);

            @media (--viewport-laptop-m) {
                transform: translateY(-2.5rem);
            }
        }
    }

    @media (--viewport-tablet) {
        &::before,
        &::after {
            content: none;
            display: none;
        }
    }
}
</style>
