<template lang="pug">
    ui-decorated-image(
        :webpSrc="require('../../../../assets/images/articles/3/composed/sick/image.webp')"
        :jpgSrc="require('../../../../assets/images/articles/3/composed/sick/image.jpg')"
    )
</template>

<script>
import UiDecoratedImage from '../../../ui/DecoratedImage.vue';

export default {
    name: 'sick',
    components: {
        UiDecoratedImage,
    },
};
</script>
