<template lang="pug">
    .wrap
        illustration.image-3(:style="style")
</template>

<script>
const Illustration = () => import(
    /* webpackChunkName: 'image-3-illn' */ '../../../../../assets/images/articles/4/composed/images/image-3.svg?inline'
);

export default {
    name: 'image-4-3',
    components: {
        Illustration,
    },
    props: {
        mouse: {
            type: Object,
            required: true,
        },
    },
    computed: {
        style() {
            const { x } = this.mouse;
            const iconX = -1 * x * 4;

            return {
                '--trx': `${iconX}px`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    max-width: rem(280);
    min-height: rem(220);
    max-height: rem(240);
    margin: 0 auto;

    @media (--viewport-tablet) {
        max-width: unset;
        min-height: unset;
        max-height: unset;
    }

    .image-3_svg {
        &__stroke-anim {
            transform-origin: 50% 50%;
            animation: strokes 4s ease-in-out-quad infinite;
            animation-delay: var(--delay);

            @keyframes strokes {
                0% {
                    opacity: 0;
                }

                50% {
                    opacity: 1;
                }

                90% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }
        }

        &__icon {
            transform: translateX(calc(var(--dir) * var(--trx)));
        }
    }
}
</style>
