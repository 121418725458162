<template lang="pug">
    component(
        v-if="component !== null",
        :is="component",
        :mouse="mouse",
        aria-hidden="true"
    )
    img(
        v-else,
        :src="require(`../../../assets/images/service/article/${name}.svg`)",
        alt="",
        aria-hidden="true",
        :style="{ display: 'block', margin: '0 auto' }"
    )
</template>

<script>
import Bacteria from './1/Bacteria.vue';
import Dna from './1/Dna.vue';
import List from './1/List.vue';
import Lungs from './1/Lungs.vue';
import Triple from './1/Triple.vue';

import Ftisis from './2/Ftisis.vue';
import Hyppocrates from './2/Hyppocrates.vue';
import Doctor1 from './2/Doctor1.vue';
import Doctor2 from './2/Doctor2.vue';
import Doctor3 from './2/Doctor3.vue';
import Sanatorium from './2/Sanatorium.vue';
import BCG from './2/BCG.vue';
import HIV from './2/HIV.vue';
import Bacteria2 from './2/Bacteria.vue';
import Antibiotics from './2/Antibiotics.vue';

import Lungs3 from './3/Lungs.vue';
import Sick from './3/Sick.vue';

import Image1 from './4/images/1.vue';
import Image2 from './4/images/2.vue';
import Image3 from './4/images/3.vue';
import Image4 from './4/images/4.vue';
import Dna4 from './4/Dna.vue';
import Mantu from './4/Mantu.vue';
import Roentgen from './4/Roentgen.vue';

import Image52 from './5/images/2.vue';
import BcgVaccine from './5/BcgVaccine.vue';
import Zelman from './5/Zelman.vue';

const components = {
    // part1
    bacteria: Bacteria,
    dna: Dna,
    list: List,
    lungs: Lungs,
    triple: Triple,
    // part2
    ftisis: Ftisis,
    hyppocrates: Hyppocrates,
    doctor_1: Doctor1,
    doctor_2: Doctor2,
    doctor_3: Doctor3,
    sanatorium: Sanatorium,
    bcg: BCG,
    hiv: HIV,
    bacteria_2: Bacteria2,
    antibiotics: Antibiotics,
    // part3
    lungs_3: Lungs3,
    sick: Sick,
    // part4
    image_1: Image1,
    image_2: Image2,
    image_3: Image3,
    image_4: Image4,
    dna_4: Dna4,
    mantu: Mantu,
    roentgen: Roentgen,
    // part5
    image_5_2: Image52,
    bcg_vaccine: BcgVaccine,
    zelman: Zelman,
    default: null,
};

export default {
    name: 'article-illustrations-reducer',
    props: {
        name: {
            type: String,
            required: true,
        },
        mouse: {
            type: Object,
            required: true,
        },
    },
    computed: {
        component() {
            return this.name in components ? components[this.name] : components.default;
        },
    },
};
</script>
