<template lang="pug">
    .ui-doctor-image-wrap
        .image-wrap
            img(:src="src" alt="")
        .icon-wrap.right-decor(v-if="rightDecorationSrc.length > 0", :style="style.rightDecoration")
            img(:src="rightDecorationSrc", alt="")
        .icon-wrap.decor(:style="style.leftDecoration")
            img(v-if="leftDecorationSrc.length > 0", :src="leftDecorationSrc", alt="")
            article-icon.icon(v-else, src="hex_green" class="opacity-50")
</template>

<script>
import ArticleIcon from '../article/Icon.vue';

export default {
    name: 'ui-doctor-image',
    components: {
        ArticleIcon,
    },
    props: {
        mouse: {
            type: Object,
            required: true,
        },
        src: {
            type: String,
            required: true,
        },
        leftDecorationSrc: {
            type: String,
            default: '',
        },
        rightDecorationSrc: {
            type: String,
            default: '',
        },
    },
    computed: {
        style() {
            const { x, y } = this.mouse;
            const iconX = -1 * x * 4;
            const iconY = y * 6;

            return {
                leftDecoration: {
                    transform: `translate(${-1 * iconX}px, ${iconY}px)`,
                },
                rightDecoration: {
                    transform: `translate(${iconX}px, ${-1 * iconY}px)`,
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-doctor-image-wrap {
    width: rem(260);
    max-width: 280px;
    margin: 0 auto;
    position: relative;

    .image-wrap {
        width: 86%;
        margin: 0 auto;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .icon-wrap {
        position: absolute;

        &.decor {
            width: rem(62);
            height: rem(62);
            bottom: rem(-31);
            left: rem(-31);
        }

        &.right-decor {
            width: rem(60);
            top: rem(-12);
            right: 0;
            z-index: 4;
        }
    }
}
</style>
