<template lang="pug">
    .wrap
        .icon-wrap
            article-icon.icon(src="hex_green" class="opacity-50 pulse")
        .icon-wrap
            article-icon.icon(src="hex_green" class="opacity-50 pulse")
        illustration.bcg
</template>

<script>
import ArticleIcon from '../../Icon.vue';

const Illustration = () => import(/* webpackChunkName: 'bcg-illn' */ '../../../../assets/images/articles/2/composed/bcg.svg?inline');

export default {
    name: 'bcg',
    components: {
        ArticleIcon,
        Illustration,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: rem(320);
    max-width: 340px;
    margin: 0 auto;
    position: relative;

    .bcg {
        width: 100%;
        position: relative;
        z-index: 2;
        object-fit: contain;
        overflow: visible;

        &_svg {
            &__oval-scale {
                animation: oval-scale-bcg 2.5s ease-out-cubic infinite;

                @keyframes oval-scale-bcg {
                    0% {
                        r: 16;
                        opacity: 0;
                    }

                    5% {
                        opacity: 1;
                    }

                    90% {
                        opacity: 1;
                    }

                    100% {
                        r: 47.5;
                        opacity: 0;
                    }
                }
            }

            &__shape-path {
                transform-origin: 0% 80%;
                animation: shape-path-bcg 2.5s ease-in-cubic infinite alternate;

                @keyframes shape-path-bcg {
                    0% {
                        transform: scaleY(1);
                    }

                    85%,
                    100% {
                        transform: scaleY(0.65);
                    }
                }
            }
        }
    }

    .icon-wrap {
        position: absolute;
        z-index: 1;

        &:nth-child(1) {
            width: rem(84);
            height: rem(70);
            top: 15%;
            left: 70%;
        }

        &:nth-child(2) {
            width: rem(42);
            height: rem(36);
            top: -15%;
            left: 90%;
        }
    }
}
</style>
