<template lang="pug">
    intersect(@enter.once="show = true")
        .article-steps(:class="{ 'show': show }")
            ui-title(variant="subtitle", component="h3", v-html="title")
            .steps-wrap
                .step(
                    v-for="(item, index) in data",
                    :key="index",
                    :class="{ 'with-image': item.type === 'image' }"
                )
                    .count(v-if="item.type === 'text'", aria-hidden="true")
                        article-icon.icon(src="hex_green", class="opacity-50", :style="style.icon")
                        span {{ index + 1 }}
                    ui-paragraph.text(v-if="item.type === 'text'", v-html="item.text")
                    img.image(
                        v-if="item.type === 'image'",
                        :src="require(`../../assets/images/articles/${currentArticleId}/${item.name}.svg`)",
                        alt="",
                        :style="style.image"
                    )
            .annotations-wrap(v-if="Array.isArray(annotations) && annotations.length > 0", aria-hidden="true")
                .annotation(v-for="({ related, text, image }, index) in annotations", :key="index")
                    .content(:class="{ 'fill': typeof image !== 'string' }")
                        .labels
                            .label(v-for="(label, index) in related", :key="label")
                                article-icon.icon(src="hex_green", class="opacity-50")
                                span {{ label }}
                        ui-paragraph.text(v-html="text")
                    .image(v-if="typeof image === 'string' && image.length > 0", aria-hidden="true")
                        img(
                            :src="require(`../../assets/images/articles/${currentArticleId}/${image}.svg`)",
                            alt="",
                            loading="lazy"
                        )
</template>

<script>
import Intersect from 'vue-intersect';

import { filledArray, articleId, requiredObject } from '../../assets/js/prop-types';

import UiParagraph from '../ui/Paragraph.vue';
import UiTitle from '../ui/Title.vue';
import ArticleIcon from './Icon.vue';

export default {
    name: 'article-steps',
    components: {
        Intersect,
        UiParagraph,
        UiTitle,
        ArticleIcon,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        data: {
            validator: filledArray,
            required: true,
        },
        annotations: {
            validator: (prop) => prop === null || filledArray(prop),
            default: null,
        },
        currentArticleId: articleId,
        mouse: {
            validator: requiredObject,
            required: true,
        },
    },
    computed: {
        style() {
            const { x, y } = this.mouse;
            const iconX = -1 * x * 6;
            const iconY = y * 6;
            const imageX = x * 16;
            const imageY = -1 * y * 16;

            return {
                icon: {
                    transform: `translate(${iconX}px, ${iconY}px)`,
                },
                image: {
                    transform: `translate(${imageX}px, ${imageY}px)`,
                },
            };
        },
    },
    data: () => ({ show: false }),
};
</script>

<style lang="scss" scoped>
.article-steps {
    width: 100%;
    max-width: 750px;
    margin: rem(54) auto;

    .steps-wrap {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;

        .step {
            width: 47.5%;
            margin: rem(34) 0 rem(42) 0;
            opacity: 0;
            transform: translateY(20px) skew(1.5deg);

            @for $i from 1 to 4 {
                &:nth-child(#{$i}) {
                    transition-delay: $i * 0.15s !important;
                }
            }

            .count {
                width: rem(73);
                height: rem(62);
                margin-bottom: rem(24);
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                font-family: var(--font-accent);
                font-size: rem(24);
                font-weight: 700;
                color: var(--violet);
                line-height: 1;

                .icon {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    object-fit: contain;
                }
            }

            .image {
                width: rem(126);
                object-fit: contain;
            }
        }
    }

    .annotations-wrap {
        width: 100%;

        .annotation {
            width: 100%;
            margin-top: rem(24);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .content {
                width: rem(280);
                max-width: 300px;

                &.fill {
                    width: 100%;
                    max-width: unset;
                }
            }

            .image {
                width: rem(330);
                max-width: 360px;
                min-height: rem(400);
                margin-left: rem(80);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .labels {
                width: 100%;
                margin: 0 0 rem(12) 0;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .label {
                    width: rem(32);
                    height: rem(28);
                    margin-right: rem(22);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    font-family: var(--font-accent);
                    font-size: rem(18);
                    font-weight: 700;
                    line-height: 1;
                    color: var(--violet);

                    .icon {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    &.show {
        .steps-wrap {
            .step {
                opacity: 1;
                transform: translateY(0) skew(0);
                transition: transform 0.5s ease-out-cubic, opacity 0.35s ease-out-cubic;
            }
        }
    }

    @media (--viewport-tablet) {
        max-width: unset;
        margin: rem-mobile(54) auto;
        padding: 0 var(--offset-x);

        .steps-wrap {
            display: block;

            .step {
                width: 100%;
                margin: rem(24) 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &.with-image {
                    display: none;
                }

                .count {
                    width: rem-mobile(73);
                    height: rem-mobile(62);
                    margin-bottom: 0;
                    font-size: rem-mobile(24);
                }

                .text {
                    width: calc(100% - #{rem-mobile(73 + 24)});
                }
            }
        }

        .annotations-wrap {
            .annotation {
                margin-top: rem-mobile(54);
                flex-wrap: wrap;
                align-content: flex-start;

                .content {
                    width: 100%;
                    max-width: unset;
                    order: 1;
                }

                .image {
                    width: 100%;
                    max-width: unset;
                    min-height: unset;
                    margin-bottom: rem-mobile(54);
                    margin-left: 0;
                    order: 0;
                }

                .labels {
                    margin: 0 0 rem-mobile(12) 0;

                    .label {
                        width: rem-mobile(32);
                        height: rem-mobile(28);
                        margin-right: rem-mobile(22);
                        font-size: rem-mobile(18);
                    }
                }
            }
        }
    }
}
</style>
