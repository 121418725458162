<template lang="pug">
    section.section
        component(
            v-for="{ id, component, ...props } in content",
            :key="id",
            :is="component",
            v-bind="props",
            :currentArticleId="currentArticleId"
        )
</template>

<script>
import { filledArray, articleId, requiredObject } from '../../assets/js/prop-types';

import ArticleArticle from './Article.vue';
import ArticleDivider from './Divider.vue';
import ArticleFullWidth from './FullWidth.vue';
import ArticleQuote from './Quote.vue';
import ArticleSteps from './Steps.vue';
import ArticleTitle from './Title.vue';
import UniqueArticleReducer from './unique/Reducer.vue';

const getComponent = (type = '') => {
    switch (type) {
        case 'article': {
            return ArticleArticle;
        }
        case 'divider': {
            return ArticleDivider;
        }
        case 'full-width': {
            return ArticleFullWidth;
        }
        case 'quote': {
            return ArticleQuote;
        }
        case 'steps': {
            return ArticleSteps;
        }
        case 'title': {
            return ArticleTitle;
        }
        case 'unique': {
            return UniqueArticleReducer;
        }
        default: {
            return null;
        }
    }
};

const requiresMouse = ['article', 'quote', 'steps'];

export default {
    name: 'app-article-section',
    props: {
        data: {
            validator: filledArray,
            required: true,
        },
        mouse: {
            validator: requiredObject,
            required: true,
        },
        currentArticleId: articleId,
    },
    computed: {
        content() {
            return this.data.map(({ type, ...rest }, index) => ({
                component: getComponent(type),
                id: `${type}__${index}`,
                mouse: requiresMouse.includes(type) ? this.mouse : undefined,
                ...rest,
            }));
        },
    },
    mounted() {
        this.$el.querySelectorAll('a[target="_blank"]').forEach((link) => {
            link.addEventListener('click', () => {
                this.$analytics.articleLinkClick(link.href);
            });
        });
    },
};
</script>

<style lang="scss" scoped>
.section {
    width: 100%;
    margin: rem(96) 0 0 0;

    @media (--viewport-tablet) {
        margin-top: rem-mobile(48);
    }
}
</style>
