<template lang="pug">
    .wrap
        illustration.image-4(:style="style")
</template>

<script>
const Illustration = () => import(
    /* webpackChunkName: 'image-4-illn' */ '../../../../../assets/images/articles/4/composed/images/image-4.svg?inline'
);

export default {
    name: 'image-4-4',
    components: {
        Illustration,
    },
    props: {
        mouse: {
            type: Object,
            required: true,
        },
    },
    computed: {
        style() {
            const { x, y } = this.mouse;
            const iconX = -1 * x * 4;
            const iconY = y * 2;

            return {
                '--trx': `${iconX}px`,
                '--try': `${iconY}px`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    max-width: rem(280);
    margin: 0 auto;

    @media (--viewport-tablet) {
        max-width: unset;
    }

    .image-4_svg {
        &__lock {
            transform: translate(var(--trx), var(--try));
        }
    }
}
</style>
