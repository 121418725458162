<template lang="pug">
    .wrap
        ui-decorated-image(
            :webpSrc="require('../../../../assets/images/articles/4/composed/roentgen/image.webp')",
            :jpgSrc="require('../../../../assets/images/articles/4/composed/roentgen/image.jpg')"
        )
</template>

<script>
import UiDecoratedImage from '../../../ui/DecoratedImage.vue';

export default {
    name: 'roentgen',
    components: {
        UiDecoratedImage,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: rem(380);
    max-width: 420px;
    margin: 0 auto;

    @media (--viewport-tablet) {
        width: 100%;
        max-width: var(--illn-max-width);
    }
}
</style>
