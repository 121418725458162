<template lang="pug">
    .wrap
        img(src="../../../../../assets/images/articles/5/composed/images/icon_2.svg", alt="")
</template>

<script>
export default {
    name: 'image-5-2',
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    max-width: rem(220);
    margin: 0 auto;

    @media (--viewport-tablet) {
        max-width: var(--illn-max-width);
    }

    img {
        width: 100%;
        object-fit: contain;
    }
}
</style>
