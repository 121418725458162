<template lang="pug">
    intersect(@enter.once="show = true")
        article.article(:class="[order, show && 'show']")
            component.text-wrap(
                v-if="typeof text === 'string'",
                v-html="text",
                :is="textComponentData.component"
                :class="textComponentData.classNames",
                v-bind="textComponentData.props"
            )
            .img-wrap(v-if="parsedImage !== null", :class="{ wide: text === null }")
                article-illustrations-reducer(:name="parsedImage", :mouse="mouse")
            .img-wrap(v-if="quote !== null", :class="{ wide: text === null }")
                article-quote(v-bind="quote", :mouse="mouse", :style="{ padding: 0, margin: 0 }")
</template>

<script>
import Intersect from 'vue-intersect';

import {
    requiredString, oneOf, requiredObject, articleId, contentImageName,
} from '../../assets/js/prop-types';

import UiParagraph from '../ui/Paragraph.vue';
import UiTitle from '../ui/Title.vue';
import ArticleQuote from './Quote.vue';
import ArticleIllustrationsReducer from './article-illustrations/Reducer.vue';

export default {
    name: 'article-article',
    components: {
        ArticleQuote,
        Intersect,
        ArticleIllustrationsReducer,
    },
    props: {
        text: {
            validator: (prop) => requiredString(prop) || prop === null,
            required: true,
        },
        image: {
            validator: contentImageName,
            default: null,
        },
        quote: {
            validator: (prop) => requiredObject(prop) || prop === null,
            default: null,
        },
        order: {
            validator: (prop) => oneOf(prop, ['image-first', 'text-first']),
            default: 'text-first',
        },
        mouse: {
            validator: requiredObject,
            required: true,
        },
        variant: {
            type: String,
            default: undefined,
        },
        componentVariant: {
            type: String,
            default: undefined,
        },
        currentArticleId: articleId,
    },
    computed: {
        parsedImage() {
            return typeof this.image === 'string' || typeof this.image === 'number' ? this.image : null;
        },
        textComponentData() {
            const component = this.variant === 'title' ? UiTitle : UiParagraph;
            const classNames = { shrink: this.image !== null || this.quote !== null };
            const defaultProps = {};
            const props = this.variant === 'title' ? {
                ...defaultProps,
                variant: this.componentVariant || 'subtitle',
            } : defaultProps;

            return {
                component,
                classNames,
                props,
            };
        },
    },
    data: () => ({ show: false }),
};
</script>

<style lang="scss" scoped>
.article {
    width: 100%;
    max-width: 750px;
    margin: rem(64) auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-wrap {
        transform: translateY(32px);
        opacity: 0;
    }

    .img-wrap {
        width: 50%;
    }

    &.image-first {
        .img-wrap {
            order: 1;
        }

        .text-wrap {
            order: 2;
        }
    }

    .shrink {
        width: 45%;
    }

    .wide {
        width: 100%;

        img {
            width: 100%;
        }
    }

    &.show {
        .text-wrap {
            opacity: 1;
            transform: translateY(0);
            transition: transform 0.5s ease-out-cubic, opacity 0.5s ease-out-cubic;
        }
    }

    @media (--viewport-tablet) {
        max-width: unset;
        margin: rem-mobile(32) auto;
        padding: 0 var(--offset-x);
        display: block;

        .img-wrap,
        .text-wrap {
            width: 100%;
            margin: rem-mobile(48) 0;
        }

        .shrink {
            width: 100%;
        }
    }
}
</style>
