<template lang="pug">
    .wrap
        illustration.list
</template>

<script>
const Illustration = () => import(/* webpackChunkName: 'article-dna' */ '../../../../assets/images/articles/1/composed/list.svg?inline');

export default {
    name: 'list',
    components: {
        Illustration,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: vw(332);
    max-width: 320px;
    margin: 0 auto;

    @media (--viewport-tablet) {
        width: 100%;
        max-width: var(--illn-max-width);
    }
}

.list {
    width: 100%;
    overflow: visible;

    .list_svg__hex {
        will-change: transform;
        animation: list-hex 4.5s ease-in-out-quad infinite;

        @for $i from 2 to 12 {
            &:nth-child(#{$i}) {
                animation-delay: $i * 0.45s;
            }
        }

        @keyframes list-hex {
            0% {
                transform: translateX(0);
            }

            50% {
                transform: translateY(calc(-15%));
            }

            100% {
                transform: translateX(0);
            }
        }
    }
}
</style>
