<template lang="pug">
    .article#article-wrap
        app-section-nav(
            v-if="showSectionNav",
            :articleId="currentArticle.id"
            :content="currentArticle.content"
        )
        intersect(
            v-for="(sectionData, index) in currentArticle.content",
            :key="`${currentArticle.id}__${index}`",
            @change="([entry]) => handleIntersection(entry, index)",
            :threshold="[0.1, 0.2, 0.3, 0.4, 0.8, 0.9, 1]"
        )
            app-article-section(
                :data="sectionData",
                :id="`app-article-section__${index + 1}`",
                :currentArticleId="currentArticle.id",
                :mouse="mouse"
            )
        app-product
        client-only
            app-read-next
</template>

<script>
import Intersect from 'vue-intersect';
import { mapActions, mapGetters, mapState } from 'vuex';

import { getArticleId, getInertedMouse } from '../assets/js/utils';

import AppArticleSection from '../components/article/Section.vue';
import AppSectionNav from '../components/article/SectionNav.vue';
import AppProduct from '../components/ProductBlock.vue';
import AppReadNext from '../components/ReadNext.vue';

export default {
    name: 'app-article',
    components: {
        AppArticleSection,
        AppSectionNav,
        AppProduct,
        AppReadNext,
        Intersect,
    },
    data: () => ({
        id: null,
        mouse: {
            x: 0,
            y: 0,
        },
        test: {
            x: null,
            y: null,
            z: null,
        },
    }),
    computed: {
        ...mapGetters('articles', ['getArticleById']),
        ...mapState('articles', ['availableIds', 'loaded']),
        currentArticle() {
            return this.getArticleById(this.id);
        },
        showSectionNav() {
            if (this.$platform.isMobile) return false;

            return this.currentArticle !== null && this.currentArticle.content !== null;
        },
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.handleArticle();
            },
        },
    },
    methods: {
        ...mapActions('articles', ['loadArticles']),
        ...mapActions('intersection', ['patchEntries', 'setSectionIntersection']),
        handleIntersection({ isIntersecting }, index) {
            if (isIntersecting) this.setSectionIntersection(index);
        },
        goToStart() {
            this.$router.push('/article/1');
        },
        async handleArticle() {
            this.id = getArticleId(this.$route.params);
            this.$nextTick(() => this.handleArticleSideEffects());

            if (Array.isArray(this.loaded) && this.loaded.includes(this.id)) return;

            if (typeof this.id === 'number' && this.availableIds.includes(this.id)) {
                await this.loadArticles([this.id]);
                this.loadArticles();
                this.setSectionIntersection(null);
                this.handleArticleSideEffects();
            } else {
                this.goToStart();
            }
        },
        handleArticleSideEffects() {
            const sanitized = this.$sanitize(this.currentArticle.meta.title);

            if (sanitized.length > 0) {
                document.title = sanitized;
                this.$analytics.visitArticle(sanitized);
            }
        },
    },
    async created() {
        await this.handleArticle();
    },
    mounted() {
        if (!this.$platform.isMobile) {
            document.body.addEventListener('mousemove', (ev) => {
                const { x, y } = getInertedMouse(ev, this.mouse);

                this.mouse.x += x;
                this.mouse.y += y;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.article {
    position: relative;
}

.test {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background-color: pink;
    color: black;

    div {
        margin: 12px 0;
    }
}
</style>
