<template lang="pug">
    .wrap
        illustration.lungs
        article-icon.icon.top(src="hex_green", class="opacity-50 pulse")
        article-icon.icon.bottom(src="hex_green", class="opacity-50 pulse")
</template>

<script>
import ArticleIcon from '../../Icon.vue';

const Illustration = () => import(/* webpackChunkName: 'article-dna' */ '../../../../assets/images/articles/1/composed/lungs.svg?inline');

export default {
    name: 'lungs',
    components: {
        ArticleIcon,
        Illustration,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: vw(296);
    max-width: 360px;
    margin: 0 auto;
    position: relative;
    transform: translateX(32px);

    @media (--viewport-tablet) {
        width: 100%;
        max-width: var(--illn-max-width);
        transform: none;
    }

    .icon {
        position: absolute;

        &.top {
            width: vw(73);
            height: vw(62);
            top: vw(-24);
            left: vw(-32);
            z-index: 100;

            @media (--viewport-tablet) {
                width: rem-mobile(58);
                height: rem-mobile(50);
                top: rem-mobile(0);
                left: rem-mobile(-25);
            }
        }

        &.bottom {
            width: vw(46);
            height: vw(39);
            bottom: vw(-24);
            right: vw(-32);
            z-index: 100;

            @media (--viewport-tablet) {
                width: rem-mobile(40);
                height: rem-mobile(32);
                bottom: rem-mobile(-20);
                right: 0;
            }
        }
    }
}

.lungs {
    width: 100%;
    object-fit: contain;
    overflow: visible;

    .lungs_svg {
        &__illn {
            will-change: transform;
            animation: lungs-illn 5s ease-in-out-cubic infinite;

            @keyframes lungs-illn {
                0%,
                100% {
                    transform: translateY(0%);
                }

                50% {
                    transform: translateY(12px);
                }
            }
        }

        &__hex {
            will-change: transform;
            animation: lungs-hex 5s ease-in-out-cubic infinite;

            @keyframes lungs-hex {
                0%,
                100% {
                    transform: translateY(0);
                }

                50% {
                    transform: translateY(calc(var(--shift) * 12px));
                }
            }
        }
    }
}
</style>
