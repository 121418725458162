<template lang="pug">
    router-link.read-next-wrap(
        v-if="linkState.show"
        :to="linkState.to",
        aria-label="Перейти к следующей главе",
    )
        div(@click="$analytics.ctaClick(linkState.text)")
            article-icon.link-icon(src="hex")
            ui-paragraph.text.label Читать далее
            ui-paragraph.text.title(v-html="linkState.text")
</template>

<script>
import { mapGetters } from 'vuex';

import ArticleIcon from './article/Icon.vue';
import UiParagraph from './ui/Paragraph.vue';

import { getArticleId } from '../assets/js/utils';

export default {
    name: 'app-read-next',
    components: {
        ArticleIcon,
        UiParagraph,
    },
    computed: {
        ...mapGetters('articles', ['articlesMeta', 'getArticleById']),
        currentArticleId() {
            return getArticleId(this.$route.params);
        },
        linkState() {
            const { currentArticleId = 0, articlesMeta = [], getArticleById } = this;
            const isRenderAvailable = Array.isArray(articlesMeta)
                && articlesMeta.length > 0
                && currentArticleId !== articlesMeta[articlesMeta.length - 1].id;

            if (isRenderAvailable) {
                const { id: nextId, meta: { title } } = getArticleById(this.currentArticleId + 1);

                return {
                    show: true,
                    text: this.$sanitize(title),
                    to: {
                        name: 'article',
                        params: {
                            id: nextId,
                        },
                    },
                };
            }

            return {
                show: false,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.read-next-wrap {
    width: rem(474);
    height: rem(122);
    margin: rem(96) auto rem(184);
    padding: rem(24) 0;
    display: block;
    position: relative;
    z-index: 2;
    font-family: var(--font-accent);
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: var(--light-green);

    &::before,
    &::after {
        content: '';
        width: 100%;
        height: 150%;
        position: absolute;
        top: -25%;
        z-index: 0;
        background: url('../assets/images/icons/decoration/product-block.svg');
        background-size: auto 100%;
        background-position: right top;
        pointer-events: none;
    }

    &::before {
        right: 120%;
    }

    &::after {
        left: 120%;
        transform: scaleX(-1);
    }

    .link-icon {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        ::v-deep img {
            width: 100%;
            height: 100%;
            object-fit: cover !important;
        }
    }

    .text {
        width: 80%;
        margin: 0 auto;
        color: currentColor;
    }

    .label {
        margin-bottom: rem(16);
        font-size: rem(16);
        letter-spacing: rem(3);
    }

    .title {
        font-size: rem(24);
        font-weight: 700;
        text-overflow: ellipsis;
        word-wrap: nowrap;
        line-height: 1;
    }

    @media (--viewport-tablet) {
        width: calc(100% - calc(2 * var(--offset-x)));
        max-width: rem-mobile(340);
        height: rem-mobile(100);
        margin: rem-mobile(64) auto rem-mobile(192);
        padding: rem-mobile(12) 0;

        .text {
            width: 75%;
        }

        .label {
            margin-bottom: rem-mobile(8);
            font-size: rem-mobile(14);
            letter-spacing: rem-mobile(3);
        }

        .title {
            font-size: rem-mobile(22);
            line-height: 1.15;
            text-overflow: unset;
            word-wrap: unset;
        }
    }

    @media (--viewport-mobile-s) {
        width: calc(100% - calc(1 * var(--offset-x)));
    }
}
</style>
