<template lang="pug">
    .wrap
        .icon-wrap
            article-icon.icon(src="hex_green" class="opacity-50 pulse")
        .image-wrap
            img(src="../../../../assets/images/articles/2/composed/hiv.svg", alt="")
        .icon-wrap
            article-icon.icon(src="hex_green" class="opacity-50 pulse")
</template>

<script>
import ArticleIcon from '../../Icon.vue';

export default {
    name: 'hiv',
    components: {
        ArticleIcon,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: rem(252);
    max-width: 280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-wrap {
        width: rem(62);
        height: rem(52);
    }

    .image-wrap {
        width: rem(100);

        img {
            width: 100%;
            object-fit: contain;
        }
    }
}
</style>
