<template lang="pug">
    intersect(@enter.once="show = true")
        article.full-width(:class="[order, show && 'show']", :style="$platform.isMobile ? {} : { height: height }")
            .text-wrap(ref="text")
                component(
                    v-for="({ type, text, is, ...props }, index) in parsedContent",
                    :key="index",
                    :is="is"
                    v-bind="props"
                )
                    span(v-if="type !== 'icon'", v-html="text")
            .image-wrap(aria-hidden="true")
                .img-cont(v-if="imageExt === 'png'")
                    img(
                        :src="require(`../../assets/images/articles/${currentArticleId}/${image}.png`)",
                        alt="Изображение к блоку текста",
                        loading="lazy",
                        :style="{ 'object-fit': 'contain' }"
                    )
                .img-cont(v-else)
                    picture
                        source(:srcset="require(`../../assets/images/articles/${currentArticleId}/${image}.webp`)", type="image/webp")
                        source(:srcset="require(`../../assets/images/articles/${currentArticleId}/${image}.jpg`)", type="image/jpeg")
                        img(
                            :src="require(`../../assets/images/articles/${currentArticleId}/${image}.jpg`)",
                            alt="Изображение к блоку текста",
                            loading="lazy"
                        )
                article-icon.icon(src="hex_green", class="pulse opacity-75 multiply", :style="{ top: 0, '--trf': -1 }")
                article-icon.icon(src="hex_green", class="pulse opacity-75 multiply", :style="{ bottom: 0, '--trf': 1 }")
</template>

<script>
import Intersect from 'vue-intersect';

import {
    oneOf, filledArray, articleId, contentImageName,
} from '../../assets/js/prop-types';

import ArticleIcon from './Icon.vue';
import UiParagraph from '../ui/Paragraph.vue';
import UiTitle from '../ui/Title.vue';

const getComponent = (type) => {
    switch (type) {
        case 'icon': {
            return ArticleIcon;
        }
        case 'title': {
            return UiTitle;
        }
        default: {
            return UiParagraph;
        }
    }
};

export default {
    name: 'article-full-width',
    components: {
        ArticleIcon,
        UiParagraph,
        UiTitle,
        Intersect,
    },
    props: {
        content: {
            validator: filledArray,
            required: true,
        },
        image: {
            validator: contentImageName,
            required: true,
        },
        imageExt: {
            type: String,
            default: 'jpg',
        },
        order: {
            validator: (prop) => oneOf(prop, ['image-first', 'text-first']),
            default: 'text-first',
        },
        currentArticleId: articleId,
    },
    computed: {
        parsedContent() {
            return this.content.map(({ type, text, ...props }) => ({
                is: getComponent(type),
                type,
                text,
                ...props,
            }));
        },
    },
    data: () => ({ show: false, height: null }),
    methods: {
        setHeight() {
            const { height } = this.$refs.text.getBoundingClientRect();

            this.height = `${(height + 84) / 16}rem`;
        },
    },
    mounted() {
        if (!this.$platform.isMobile) {
            this.$nextTick(() => this.setHeight());
            window.addEventListener('resize', this.setHeight);
        }
    },
    beforeDestroy() {
        if (!this.$platform.isMobile) {
            window.removeEventListener('resize', this.setHeight);
        }
    },
};
</script>

<style lang="scss" scoped>
.full-width {
    width: 100%;
    margin: rem(64) 0;
    display: flex;
    align-items: center;
    background-color: rgba(212, 238, 235, 0.575);

    .image-wrap {
        width: vw(520);
        min-width: 50%;
        height: 100%;
        // max-height: vw(580);
        position: relative;
        z-index: 1;
        opacity: 0;

        .icon {
            width: vw(39);
            position: absolute;
            z-index: 2;
            transform: translate(-50%, calc(var(--trf) * 50%));
        }

        .img-cont {
            width: 100%;
            height: 100%;
            overflow: hidden;

            picture,
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .text-wrap {
        width: calc(100% - #{vw(520)});
        padding: rem(42) 0;

        > * {
            transform: translateY(20%) skew(1.5deg);
            opacity: 0;
        }

        @for $i from 1 to 4 {
            *:nth-child(#{$i}) {
                transition-delay: $i * 0.1s !important;
            }
        }
    }

    &.image-first {
        justify-content: flex-start;

        .image-wrap {
            order: 0;

            .icon {
                right: 0;
                transform: translate(50%, calc(var(--trf) * 50%));
            }
        }

        .text-wrap {
            padding-left: var(--offset-x);
            padding-right: var(--content-offset-x);
            order: 1;
        }
    }

    &.text-first {
        justify-content: flex-end;

        .image-wrap {
            order: 1;

            .icon {
                left: 0;
            }
        }

        .text-wrap {
            padding-left: var(--content-offset-x);
            padding-right: var(--offset-x);
            order: 0;
        }
    }

    &.show {
        .image-wrap {
            opacity: 1;
            transition: opacity 0.35s ease-out-cubic;
        }

        .text-wrap {
            > * {
                transform: translateY(0) skew(0);
                opacity: 1;
                transition: transform 0.5s ease-out-cubic, opacity 0.35s ease-out-cubic;
            }
        }
    }

    @media (--viewport-tablet) {
        margin: rem-mobile(64) 0;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: flex-start;

        .image-wrap {
            width: 100%;
            height: unset;

            .icon {
                width: rem-mobile(40);
            }
        }

        .text-wrap {
            width: 100%;
            padding: 0;
        }

        &.image-first,
        &.text-first {
            .image-wrap {
                order: 0;

                .icon {
                    left: unset;
                    right: 0;
                    transform: translate(50%, calc(var(--trf) * 50%));

                    &:nth-last-child(1) {
                        left: 0;
                        right: unset;
                        transform: translate(-50%, calc(var(--trf) * 50%));
                    }
                }
            }

            .text-wrap {
                padding: rem-mobile(40) var(--offset-x);
                order: 1;
            }
        }
    }
}
</style>
