<template lang="pug">
    intersect(@enter.once="show = true")
        blockquote.quote(:class="{ 'show': show }")
            ui-title.text(v-html="text", variant="subtitle", component="p")
            footer.quote-footer(v-if="typeof author === 'string' && author.length > 0", v-html="author")
            article-icon.icon(
                src="hex_green",
                :class="[direction, 'opacity-50', 'multiply']",
                :style="{ transform: `translate(calc(${translate.x} * var(--shift) * 1px), calc(${translate.y} * var(--shift) * 1px))` }"
            )
            article-icon.icon(
                src="hex_green",
                :class="[direction, 'opacity-50', 'multiply']",
                :style="{ transform: `translate(calc(${translate.x} * var(--shift) * 1px), calc(${translate.y} * var(--shift) * 1px))` }"
            )
            article-icon.icon(
                src="hex_green",
                :class="[direction, 'opacity-50', 'multiply']",
                :style="{ transform: `translate(calc(${translate.x} * var(--shift) * 1px), calc(${translate.y} * var(--shift) * 1px))` }"
            )
</template>

<script>
import Intersect from 'vue-intersect';

import { oneOf, articleId, requiredObject } from '../../assets/js/prop-types';

import ArticleIcon from './Icon.vue';
import UiTitle from '../ui/Title.vue';

export default {
    name: 'article-quote',
    components: {
        ArticleIcon,
        UiTitle,
        Intersect,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        author: {
            type: String,
            default: null,
        },
        direction: {
            validator: (prop) => oneOf(prop, ['ltr', 'rtl', 'ltr-left', 'rtl-left']),
            default: 'ltr',
        },
        currentArticleId: {
            ...articleId,
            required: false,
        },
        mouse: {
            validator: requiredObject,
            required: true,
        },
    },
    computed: {
        translate() {
            return {
                x: -1 * this.mouse.x * 20,
                y: this.mouse.y * 15,
            };
        },
    },
    data: () => ({ show: false }),
};
</script>

<style lang="scss" scoped>
.quote {
    width: 100%;
    // max-width: rem(470);
    max-width: 750px;
    // margin: rem(60) var(--content-offset-x) rem(90);
    margin: rem(60) auto rem(90);
    padding: 0;
    position: relative;
    z-index: 0;
    box-sizing: content-box;

    .icon {
        width: rem(30);
        position: absolute;
        z-index: -1;
        will-change: transform;
        opacity: 0;

        &:nth-of-type(1) {
            --shift: 1.2;

            top: 120%;
            left: 0;

            &.rtl {
                top: unset;
                bottom: 120%;

                &-left {
                    top: unset;
                    bottom: 120%;
                }
            }
        }

        &:nth-of-type(2) {
            --shift: -0.5;

            width: rem(54);
            top: 0%;
            right: rem(-28);

            &.rtl {
                top: unset;
                bottom: 0;

                &-left {
                    top: unset;
                    bottom: 0;
                    right: rem(18);
                }
            }
        }

        &:nth-of-type(3) {
            --shift: 1;

            top: rem(-30);
            right: rem(-72);

            &.rtl {
                top: unset;
                bottom: rem(-30);

                &-left {
                    top: unset;
                    bottom: rem(-48);
                    right: rem(0);
                }
            }
        }
    }

    .text {
        opacity: 0;
    }

    .quote-footer {
        margin-top: rem(12);
        font-family: var(--font-accent);
        font-size: rem(16);
        font-weight: 700;
        color: var(--teal);
        opacity: 0;
    }

    &.show {
        .icon {
            opacity: 1;
            transition: opacity 0.25s ease-out-cubic;
        }

        .text,
        .quote-footer {
            opacity: 1;
            transition: opacity 0.35s ease-out-cubic;
        }
    }

    @media (--viewport-tablet) {
        max-width: unset;
        margin: rem-mobile(28) auto rem-mobile(48);
        padding: 0 var(--offset-x);
        box-sizing: border-box;

        .quote-footer {
            margin-top: rem-mobile(4);
            font-size: rem-mobile(16);
        }

        .icon {
            width: rem-mobile(30);

            &:nth-of-type(1) {
                --shift: 1.2;

                top: 106%;
                left: rem-mobile(12);

                &.rtl {
                    top: unset;
                    bottom: 106%;
                }
            }

            &:nth-of-type(2) {
                --shift: -0.5;

                width: rem-mobile(54);
                top: -10%;
                right: rem-mobile(12);

                &.rtl {
                    top: unset;
                    bottom: -10%;
                }
            }

            &:nth-of-type(3) {
                --shift: 1;

                top: rem-mobile(54);
                right: rem-mobile(-12);

                &.rtl {
                    top: unset;
                    bottom: rem-mobile(54);
                }
            }
        }
    }
}
</style>
