<template lang="pug">
    .wrap
        article-icon.icon(src="hex" class="pulse")
        article-icon.icon(src="hex_green" class="pulse opacity-50")
        article-icon.icon(src="hex_green" class="pulse opacity-50")
        article-icon.icon(src="hex" class="pulse")
        .image-wrap
            img(src="../../../../assets/images/articles/5/composed/bcg_vaccine/icon.svg", alt="")
</template>

<script>
import ArticleIcon from '../../Icon.vue';

export default {
    name: 'bcg-vaccine',
    components: {
        ArticleIcon,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    max-width: rem(240);
    margin: 0 auto;
    position: relative;

    .icon {
        position: absolute;

        &:nth-child(1) {
            width: rem(32);
            height: rem(26);
            bottom: 25%;
            left: -10%;
        }

        &:nth-child(2) {
            width: rem(42);
            height: rem(34);
            bottom: 0%;
            left: 0%;
        }

        &:nth-child(3) {
            width: rem(68);
            height: rem(56);
            top: 5%;
            right: 0%;
        }

        &:nth-child(4) {
            width: rem(32);
            height: rem(26);
            top: 40%;
            right: -10%;
        }
    }

    .image-wrap {
        width: 45%;
        height: 100%;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    @media (--viewport-tablet) {
        max-width: var(--illn-max-width);

        .icon {
            &:nth-child(1) {
                width: rem-mobile(32);
                height: rem-mobile(26);
                bottom: 25%;
                left: 10%;
            }

            &:nth-child(2) {
                width: rem-mobile(42);
                height: rem-mobile(34);
                bottom: 0%;
                left: 15%;
            }

            &:nth-child(3) {
                width: rem-mobile(68);
                height: rem-mobile(56);
                top: 5%;
                right: 10%;
            }

            &:nth-child(4) {
                width: rem-mobile(32);
                height: rem-mobile(26);
                top: 40%;
                right: 5%;
            }
        }

        .image-wrap {
            width: 35%;
        }
    }
}
</style>
