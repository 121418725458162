<template lang="pug">
    .wrap
        .icon-wrap.icon1
            article-icon.icon(src="hex_green" class="opacity-50", :style="style.icon1")
        .icon-wrap.icon2
            article-icon.icon(src="hex_green" class="opacity-50", :style="style.icon2")
        .image-wrap
            img(src="../../../../assets/images/articles/2/composed/hyppocrates/hyppocrates.png", alt="")
</template>

<script>
import ArticleIcon from '../../Icon.vue';

export default {
    name: 'hyppocrates',
    components: {
        ArticleIcon,
    },
    props: {
        mouse: {
            type: Object,
            required: true,
        },
    },
    computed: {
        style() {
            const { x, y } = this.mouse;
            const iconX = -1 * x * 12;
            const iconY = y * 12;

            return {
                icon1: {
                    transform: `translate(${-1 * iconX}px, ${iconY}px)`,
                },
                icon2: {
                    transform: `translate(${iconX}px, ${-1 * iconY}px)`,
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: vw(252);
    max-width: 280px;
    margin: 0 auto;
    position: relative;

    @media (--viewport-tablet) {
        width: 67%;
        max-width: var(--illn-max-width);
    }

    .icon-wrap {
        position: absolute;
        z-index: 0;

        &.icon1 {
            width: rem(96);
            height: rem(80);
            top: rem(32);
            right: 0;
        }

        &.icon2 {
            width: rem(74);
            height: rem(62);
            top: 60%;
            left: 0;
        }
    }

    .image-wrap {
        width: 80%;
        max-width: 224px;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
}
</style>
