<template lang="pug">
    component(:is="component")
</template>

<script>
const components = {
    algorithm: () => import(/* webpackChunkName: 'algorithm' */ './components/1/Algorithm.vue'),
    interview: () => import(/* webpackChunkName: 'interview' */ './components/1/Interview.vue'),
    'tb-test': () => import(/* webpackChunkName: 'tb-test' */ './components/1/Test.vue'),
    spreading: () => import(/* webpackChunkName: 'spreading' */ './components/3/Spreading.vue'),
    factors: () => import(/* webpackChunkName: 'factors' */ './components/4/Factors.vue'),
    diagnostics: () => import(/* webpackChunkName: 'diagnostics' */ './components/4/Diagnostics.vue'),
    methods: () => import(/* webpackChunkName: 'methods' */ './components/4/Methods.vue'),
    hexs: () => import(/* webpackChunkName: 'hexs' */ './components/5/Hexs.vue'),
    list_items_bottom: () => import(/* webpackChunkName: 'list_items_bottom' */ './components/5/ListItemsBottom.vue'),
    list_items_top: () => import(/* webpackChunkName: 'list_items_top' */ './components/5/ListItemsTop.vue'),
    medicine_history: () => import(/* webpackChunkName: 'medicine_history' */ './components/5/MedicineHistory.vue'),
    schema_components: () => import(/* webpackChunkName: 'schema_components' */ './components/5/SchemaComponents.vue'),
    death_stats: () => import(/* webpackChunkName: 'death_stats' */ './components/6/DeathStats.vue'),
    key_indicators: () => import(/* webpackChunkName: 'key_indicators' */ './components/6/KeyIndicators.vue'),
    lti: () => import(/* webpackChunkName: 'lti' */ './components/6/Lti.vue'),
    spread: () => import(/* webpackChunkName: 'spread' */ './components/6/Spread.vue'),
    default: null,
};

export default {
    name: 'app-unique-article-reducer',
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    computed: {
        component() {
            return this.name in components ? components[this.name] : components.default;
        },
    },
};
</script>
