<template lang="pug">
    .wrap
        illustration.antibiotics
</template>

<script>
const Illustration = () => import(
    /* webpackChunkName: 'antibiotics-illn' */ '../../../../assets/images/articles/2/composed/antibiotics.svg?inline'
);

export default {
    name: 'antibiotics',
    components: {
        Illustration,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: rem(220);
    max-width: 260px;
    margin: 0 auto;
    position: relative;

    .antibiotics {
        width: 100%;
        object-fit: contain;
        overflow: visible;
    }
}
</style>
