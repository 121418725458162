<template lang="pug">
    .wrap
        .icon-wrap
            article-icon.icon(src="hex" class="opacity-50 pulse")
        illustration.lungs
</template>

<script>
import ArticleIcon from '../../Icon.vue';

const Illustration = () => import(/* webpackChunkName: 'lungs_3-illn' */ '../../../../assets/images/articles/3/composed/lungs.svg?inline');

export default {
    name: 'lungs_3',
    components: {
        ArticleIcon,
        Illustration,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: rem(240);
    max-width: 320px;
    margin: 0 auto;
    position: relative;

    @media (--viewport-tablet) {
        width: 75%;
        max-width: var(--illn-max-width);
    }

    .icon-wrap {
        width: rem(100);
        height: rem(84);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -10%);
    }
}

.lungs {
    width: 100%;
    object-fit: contain;
    overflow: visible;

    &_svg {
        &__top-polys {
            .lungs_svg__item {
                transform-origin: 50% 50%;
                animation: top-poly 2s ease-out-quad infinite;
                animation-delay: var(--delay);

                @keyframes top-poly {
                    0% {
                        opacity: 0;
                        transform: translate(0, -16px);
                    }

                    40% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                        transform: translate(0, 0);
                    }
                }
            }
        }

        &__bot-polys {
            .lungs_svg__item {
                transform-origin: 50% 50%;
                animation: bot-poly 2.5s ease-out-quad infinite;
                animation-delay: var(--delay);

                @keyframes bot-poly {
                    0% {
                        opacity: 0;
                        transform: scale(0.2);
                    }

                    40% {
                        opacity: 1;
                    }

                    85% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                        transform: scale(1);
                    }
                }
            }
        }
    }
}
</style>
