<template lang="pug">
    .wrap
        ui-decorated-image(
            :webpSrc="require('../../../../../assets/images/articles/4/composed/images/2.webp')",
            :jpgSrc="require('../../../../../assets/images/articles/4/composed/images/2.jpg')"
        )
</template>

<script>
import UiDecoratedImage from '../../../../ui/DecoratedImage.vue';

export default {
    name: 'image-4-2',
    components: {
        UiDecoratedImage,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    max-width: rem(320);
    margin: 0 auto;

    @media (--viewport-tablet) {
        max-width: unset;
    }
}
</style>
