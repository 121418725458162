<template lang="pug">
    .wrap
        .icon-wrap
            article-icon.icon(src="hex_green" class="opacity-50 pulse")
        .icon-wrap
            article-icon.icon(src="hex" class="opacity-75 pulse")
        .icon-wrap
            article-icon.icon(src="hex" class="opacity-75 pulse")
        .icon-wrap
            article-icon.icon(src="hex_green" class="opacity-50 pulse")
        .image-wrap
            picture
                source(:srcset="webpSrc", type="image/webp")
                source(:srcset="jpgSrc", type="image/jpeg")
                img(
                    :src="jpgSrc",
                    alt="",
                    loading="lazy"
                )
</template>

<script>
import ArticleIcon from '../article/Icon.vue';

export default {
    name: 'ui-decorated-image',
    components: {
        ArticleIcon,
    },
    props: {
        webpSrc: {
            type: String,
            required: true,
        },
        jpgSrc: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    margin: 0 auto;
    position: relative;

    @media (--viewport-tablet) {
        width: 85%;
        max-width: var(--illn-max-width);
    }

    .image-wrap {
        width: 100%;
        position: relative;
        z-index: 1;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .icon-wrap {
        position: absolute;
        z-index: 2;

        &:nth-child(1),
        &:nth-child(4) {
            width: rem(62);
            height: rem(52);

            @media (--viewport-tablet) {
                width: rem-mobile(42);
                height: rem-mobile(34);
            }
        }

        &:nth-child(2),
        &:nth-child(3) {
            width: rem(32);
            height: rem(26);

            @media (--viewport-tablet) {
                width: rem(24);
                height: rem(18);
            }
        }

        &:nth-child(1) {
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
        }

        &:nth-child(2) {
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
        }

        &:nth-child(3) {
            bottom: 0;
            left: 0;
            transform: translate(-50%, 50%);
        }

        &:nth-child(4) {
            bottom: 0;
            right: 0;
            transform: translate(50%, 50%);
        }
    }
}
</style>
