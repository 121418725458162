<template lang="pug">
    .wrap
        illustration.ftisis
</template>

<script>
const Illustration = () => import(/* webpackChunkName: 'ftisis-illn' */ '../../../../assets/images/articles/2/composed/ftisis.svg?inline');

export default {
    name: 'ftisis',
    components: {
        Illustration,
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: vw(278);
    max-width: 320px;
    margin: 0 auto;

    @media (--viewport-tablet) {
        width: 100%;
        max-width: var(--illn-max-width);
    }
}

.ftisis {
    width: 100%;
    object-fit: contain;
    overflow: visible;

    &_svg {
        &__ftisis-circle {
            transform-origin: 50% 50%;
            animation: ftisis-circle 120s linear infinite;

            @keyframes ftisis-circle {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(-360deg);
                }
            }
        }
    }
}
</style>
