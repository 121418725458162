<template lang="pug">
    .wrap
        img(src="../../../../assets/images/articles/4/composed/dna/icon.svg", alt="")
</template>

<script>

export default {
    name: 'dna-4',
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    max-width: rem(390);
    margin: 0 auto;

    @media (--viewport-tablet) {
        max-width: var(--illn-max-width);
    }

    img {
        width: 100%;
        object-fit: contain;
    }
}
</style>
