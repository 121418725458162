<template lang="pug">
    .wrap(@click="handleClick()")
        .illn-icon.top
            .inner
                article-icon.icon(src="hex_green", class="opacity-50")
                img.image(src="../../../../assets/images/articles/1/composed/triple/2.svg", alt="", loading="lazy")
        .illn-icon.left
            .inner
                article-icon.icon(src="hex_green", class="opacity-50")
                img.image(src="../../../../assets/images/articles/1/composed/triple/1.svg", alt="", loading="lazy")
        .illn-icon.right
            .inner
                article-icon.icon(src="hex_green", class="opacity-50")
                img.image(src="../../../../assets/images/articles/1/composed/triple/3.svg", alt="", loading="lazy")
        img.center-icon(src="../../../../assets/images/articles/1/composed/triple/center.svg", alt="", loading="lazy")
</template>

<script>
import ArticleIcon from '../../Icon.vue';

export default {
    name: 'triple',
    components: {
        ArticleIcon,
    },
    props: {
        mouse: {
            type: Object,
            required: true,
        },
    },
    methods: {
        handleClick() {
            const article = document.querySelector('#app-article-section__3');

            if (article instanceof Element) {
                article.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: rem(372);
    height: rem(326);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    @media (--viewport-tablet) {
        width: rem-mobile(262);
        height: rem-mobile(256);
    }

    .center-icon {
        width: rem(218);
        height: rem(196);
        // transform: translateY(rem(107 / 4.5));
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -20%);

        @media (--viewport-tablet) {
            width: rem-mobile(154);
            height: rem-mobile(144);
        }
    }

    .illn-icon {
        width: rem(126);
        height: rem(107);
        position: absolute;

        @media (--viewport-tablet) {
            width: rem-mobile(90);
            height: rem-mobile(75);
        }

        .inner {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .icon {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                object-fit: contain;
            }

            .image {
                width: 60%;
                height: 60%;
                object-fit: contain;
            }
        }

        &.top {
            top: 0;
            left: calc(50% - #{rem(126 / 2)});
            animation: triple-top 4s ease infinite;

            @media (--viewport-tablet) {
                top: rem-mobile(8);
                left: calc(50% - #{rem-mobile(90 / 2)});
            }

            @keyframes triple-top {
                0%,
                100% {
                    transform: translateY(0);
                }

                50% {
                    transform: translateY(10%);
                }
            }
        }

        &.left {
            bottom: 0;
            left: 0;
            animation: triple-left 4s ease infinite;

            @keyframes triple-left {
                0%,
                100% {
                    transform: translate(0%, 0%);
                }

                50% {
                    transform: translate(10%, -10%);
                }
            }
        }

        &.right {
            bottom: 0;
            right: 0;
            animation: triple-right 4s ease infinite;

            @keyframes triple-right {
                0%,
                100% {
                    transform: translate(0%, 0%);
                }

                50% {
                    transform: translate(-10%, -10%);
                }
            }
        }
    }
}
</style>
